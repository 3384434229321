// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/usr/local/youhuiquan/coupon-ui-frontend-umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/register",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/layouts/UserLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/register/email",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Register__Email' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/pages/Register/Email'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/register/forget",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Register__Forget' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/pages/Register/Forget'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/register/reset",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Register__Reset' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/pages/Register/Reset'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/register",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Register' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/pages/Register'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/registerCopy",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/layouts/UserLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/registerCopy/email",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__RegisterCopy__Email' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/pages/RegisterCopy/Email'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/registerCopy/forget",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__RegisterCopy__Forget' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/pages/RegisterCopy/Forget'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/registerCopy/reset",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__RegisterCopy__Reset' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/pages/RegisterCopy/Reset'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/registerCopy",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__RegisterCopy' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/pages/RegisterCopy'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/user",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/layouts/UserLayout'), loading: LoadingComponent}),
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
    "routes": [
      {
        "path": "/user/password",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Password' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/pages/User/Password'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user/favorite",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Favorite' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/pages/User/Favorite'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/layouts/UserLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Login' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/pages/Login'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/jingxuan",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__Menu2Layout' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/layouts/Menu2Layout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/jingxuan/:fenlei",
        "name": "jingxuan",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CouponJingxuan' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/pages/CouponJingxuan'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__MenuLayout' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/layouts/MenuLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/home",
        "name": "home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CouponList' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/pages/CouponList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/coupon/:id",
        "name": "coupon",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CouponDetail' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/pages/CouponDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/catalog/:id",
        "name": "catalog",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CouponList' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/pages/CouponList'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/*",
        "redirect": "/home",
        "exact": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/usr/local/youhuiquan/coupon-ui-frontend-umi/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
