export const catalog = {
  '-1': '其他',
  1: '女装',
  2: '母婴',
  3: '美妆',
  4: '居家日用',
  5: '鞋品',
  6: '美食',
  7: '文娱车品',
  8: '数码家电',
  9: '男装',
  10: '内衣',
  11: '箱包',
  12: '配饰',
  13: '户外运动',
  14: '家装家纺'
}

export const jingxuan = {
  0: '大额券',
  1: '实时热销',
  2: '精品券',
  3: '品牌券',
  4: '好券直播',
}
