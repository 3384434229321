import request from '@/utils/request';

export async function queryList(params) {
  return request('/api/coupon', {
    data: params
  });
}

export async function queryCatalog({cid, ...params}) {
  return request(`/api/coupon/catalog/${cid}`, {
    data: params
  });
}

export async function queryJingxuan(feilei) {
  return request(`/api/coupon/jingxuan/${feilei}`);
}

export async function queryHot() {
  return request('/api/coupon/hot');
}

export async function queryRelated(params) {
  return request('/api/coupon/related', {
    data: params
  });
}

export async function queryOne(id) {
  return request(`/api/coupon/${id}`);
}

