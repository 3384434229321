import {queryCatalog, queryHot, queryJingxuan, queryList, queryOne, queryRelated} from '@/services/coupon'
import {catalog, jingxuan} from '@/utils/common'

const CouponModel = {
  namespace: 'coupon',
  state: {
    list: [],
    maxPage: 1,
    nowPage: 1,
    detail: {},
    isHot: true,
    hot: [],
    related: [],
    jingxuan: []
  },
  effects: {
    * fetchList({payload}, {call, put}) {
      const response = yield call(queryList, payload);
      yield put({
        type: 'saveList',
        payload: response,
      });
    },
    * fetchCatalog({payload}, {call, put}) {
      const response = yield call(queryCatalog, payload);
      yield put({
        type: 'saveList',
        payload: response,
      });
    },
    * fetchJingxuan({payload}, {call, put}) {
      const response = yield call(queryJingxuan, payload);
      yield put({
        type: 'saveJingxuan',
        payload: response,
      });
    },
    * fetchOne({payload}, {call, put}) {
      const response = yield call(queryOne, payload);
      // saveOne
      yield put({
        type: 'saveOne',
        payload: response,
      });
      // related
      if (response._id && response.cid) {
        const relatedResponse = yield call(queryRelated, {
          id: response._id,
          cid: response.cid
        });
        yield put({
          type: 'saveRelated',
          payload: relatedResponse,
        });
        yield put({type: 'setRelated'})
      } else {
        yield put({type: 'setHot'})
      }
      // change title
      let title = ''
      if (response.shopName) {
        title += `${response.shopName} `
      }
      if (response.dtitle) {
        title += `${response.dtitle} `
      } else if (response.title) {
        title += `${response.title} `
      }
      if (response.actualPrice) {
        title += `券后${response.actualPrice}元`
      }
      title += ' - coupon'
      document.title = title
      yield put({
        type: 'saveOne',
        payload: response,
      });
    },
    * fetchHot(_, {call, put}) {
      const response = yield call(queryHot);
      yield put({
        type: 'saveHot',
        payload: response,
      });
    },
  },
  reducers: {
    saveList(state, action) {
      return {
        ...state,
        list: action.payload.list || [],
        maxPage: action.payload.maxPage || 1,
        nowPage: action.payload.nowPage || 1,
      };
    },
    saveOne(state, action) {
      return {
        ...state,
        detail: action.payload || {},
      };
    },
    saveHot(state, action) {
      return {
        ...state,
        hot: action.payload || [],
      };
    },
    saveRelated(state, action) {
      return {
        ...state,
        related: action.payload || [],
      };
    },
    saveJingxuan(state, action) {
      return {
        ...state,
        jingxuan: action.payload || [],
      };
    },
    setHot(state) {
      return {
        ...state,
        isHot: true
      }
    },
    setRelated(state) {
      return {
        ...state,
        isHot: false
      }
    }
  },
  subscriptions: {
    setup({dispatch, history}) {
      return history.listen(({pathname, query}) => {
        if (pathname === '/home') {
          // change title
          document.title = 'coupon'
          // fetchList
          let page = 1
          if (query.page && parseInt(query.page, 10)) {
            page = parseInt(query.page, 10)
          }
          let kw = ''
          if (query.kw) {
            kw = query.kw
          }
          dispatch({
            type: 'fetchList', payload: {
              page,
              kw
            }
          })
          // fetchHot
          dispatch({type: 'fetchHot'})
          // setHot
          dispatch({type: 'setHot'})
        } else if (pathname.indexOf('/coupon/') === 0) {
          // scroll to top
          window.scrollTo(0, 0)
          const id = pathname.substring(8)
          dispatch({
            type: 'fetchOne', payload: id
          })
        } else if (pathname.indexOf('/catalog/') === 0) {
          const cid = pathname.substring(9)
          if (catalog[cid]) {
            // change title
            document.title = `${catalog[cid]} - coupon`
          }
          let page = 1
          if (query.page && parseInt(query.page, 10)) {
            page = parseInt(query.page, 10)
          }
          let kw = ''
          if (query.kw) {
            kw = query.kw
          }
          dispatch({
            type: 'fetchCatalog', payload: {
              cid,
              page,
              kw
            }
          })
          // fetchHot
          dispatch({type: 'fetchHot'})
          // setHot
          dispatch({type: 'setHot'})
        } else if (pathname.indexOf('/jingxuan/') === 0) {
          const fenlei = pathname.substring(10)
          if (jingxuan[fenlei]) {
            // change title
            document.title = `${jingxuan[fenlei]} - coupon`
          }
          dispatch({
            type: 'fetchJingxuan', payload: fenlei
          })
          // fetchHot
          dispatch({type: 'fetchHot'})
          // setHot
          dispatch({type: 'setHot'})
        }
      })
    }
  }
};
export default CouponModel;
