import { PageLoading } from '@ant-design/pro-layout'; // loading components from code split
// https://umijs.org/plugin/umi-plugin-react.html#dynamicimport
import style from "./index.less"
const PageLoading1 = (props) => {
    return (
      <div className={style.footer}>
        <PageLoading />
      </div>)
  }
export default PageLoading1;
