import {getFavorite, login, logout, userinfo} from '@/services/user'
import {history} from 'umi'

const UserModel = {
  namespace: 'user',
  state: {
    user: {},
    favorite: [],
    auto: false
  },
  effects: {
    * login({payload}, {call, put}) {
      const response = yield call(login, payload)
      if (response.result === 'OK') {
        yield put({
          type: 'saveUser',
          payload: response,
        })
        const responseFav = yield call(getFavorite)
        yield put({
          type: 'saveFavorite',
          payload: responseFav.list
        });
        const {location} = history
        if (location.query && location.query.redirect && location.query.redirect != '/login' && location.query.redirect != '/registerCopy'
            && location.query.redirect != '/registerCopy/reset' && location.query.redirect != '/registerCopy/forget' && location.query.redirect != '/user/password') {
          history.push(location.query.redirect)
        } else {
          history.push('/')
        }
        return 0
      } else if (response.result === 'useLevel-error') {
        return 1
      } else {
        return 2
      }
    },
    * logout(_, {call, put}) {
      yield call(logout);
      yield put({
        type: 'saveUser',
        payload: {},
      });
      
      yield put({
        type: 'saveFavorite',
        payload: [],
      });
    },
    * getFavorite(_, {call, put}) {
      const response = yield call(getFavorite);
      yield put({
        type: 'saveFavorite',
        payload: response.list,
      });
    }
  },
  reducers: {
    saveUser(state, action) {
      return {
        ...state,
        user: action.payload
      };
    },
    saveFavorite(state, action) {
      return {
        ...state,
        favorite: action.payload
      };
    },
    saveAuto(state) {
      return {
        ...state,
        auto: true
      };
    }
  },
  subscriptions: {
    setup({dispatch}) {
      userinfo().then(res => {
        if (res.result === 'OK') {
          dispatch({
            type: 'saveUser',
            payload: {
              email: res.email,
              username: res.username
            }
          })
          dispatch({
            type: 'getFavorite'
          })
        }
        dispatch({
          type: 'saveAuto'
        })
      })
    }
  }
};
export default UserModel;
