import request from '@/utils/request';

export async function registerEmail(params) {
  return request('/api/user/register/email', {
    data: params
  });
}

export async function registerEmailCheck(params) {
  return request('/api/user/register/check', {
    data: params
  });
}

export async function registerCopyEmailCheck(params) {
  return request('/api/user/registerCopy/email/check', {
    data: params
  });
}

export async function registerUsernameCheck(params) {
  return request('/api/user/register/username/check', {
    data: params
  });
}

export async function registerAdd(params) {
  return request('/api/user/register/add', {
    data: params
  });
}

export async function registerCopyAdd(params) {
  return request('/api/user/registerCopy/add', {
    data: params
  });
}

export async function registerCopyActivate(params) {
  return request('/api/user/registerCopy/activate', {
    data: params
  });
}

export async function loginEmail(params) {
  return request('/api/user/login/activateEmail', {
    data: params
  });
}

export async function login(params) {
  return request('/api/user/login', {
    data: params
  });
}

export async function logout() {
  return request('/api/user/logout');
}

export async function forget(params) {
  return request('/api/user/forget', {
    data: params
  });
}

export async function reset(params) {
  return request('/api/user/reset', {
    data: params
  });
}

export async function password(params) {
  return request('/api/user/password', {
    data: params
  });
}

export async function userinfo() {
  return request('/api/user/user', {});
}

export async function getFavorite() {
  return request('/api/user/favorite', {data: {}});
}

export async function addFavorite(params) {
  return request('/api/user/favorite/add', {
    data: params
  });
}

export async function deleteFavorite(params) {
  return request('/api/user/favorite/delete', {
    data: params
  });
}

export async function getFavoriteCoupon() {
  return request('/api/user/favoriteDetail', {data: {}});
}
