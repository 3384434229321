export default {
  'pages.layouts.userLayout.title':
    'Ant Design is the most influential web design specification in Xihu district',
  'pages.login.accountLogin.tab': 'Account Login',
  'pages.login.accountLogin.errorMessage': 'Incorrect username/password（admin/ant.design)',
  'pages.login.username.placeholder': 'Username: admin or user',
  'pages.login.username.required': 'Please input your username!',
  'pages.login.password.placeholder': 'Password: ant.design',
  'pages.login.password.required': 'Please input your password!',
  'pages.login.phoneLogin.tab': 'Phone Login',
  'pages.login.phoneLogin.errorMessage': 'Verification Code Error',
  'pages.login.phoneNumber.placeholder': 'Phone Number',
  'pages.login.phoneNumber.required': 'Please input your phone number!',
  'pages.login.phoneNumber.invalid': 'Phone number is invalid!',
  'pages.login.captcha.placeholder': 'Verification Code',
  'pages.login.captcha.required': 'Please input verification code!',
  'pages.login.phoneLogin.getVerificationCode': 'Get Code',
  'pages.getCaptchaSecondText': 'sec(s)',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'Forgot Password ?',
  'pages.login.submit': 'Submit',
  'pages.login.loginWith': 'Login with :',
  'pages.login.registerAccount': 'Register Account',
};
